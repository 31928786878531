import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm, FieldArray } from "redux-form";
import _ from "lodash";
import { format } from "date-fns";
import Pagination from "react-js-pagination";
import { fetchPlanDetails, updateCredits } from "./actions";
import {
  CustomSpinner,
  renderInputField,
  renderSelect,
  renderCheckbox,
} from "../Common/base-component";
import { Toastify } from "../Common/Toastify";
import Loader from "../Common/Loader";
import ArrowBack from "../../assets/img/icons/arrow-back.svg";
import PlanIcon from "../../assets/img/icons/plan.svg";

const AddCredit = (props) => {
  const { isLoggedIn, session, currentPlanDetails, handleSubmit } = props;
  const { id } = useParams();
  const Navigate = useNavigate();
  const Location = useLocation();
  const [pageNumber, setPagenumber] = useState(1);
  const [logType, setLogType] = useState("log");
  const [searchText, setSearchText] = useState(null);
  const [processLoading, setProcessLoading] = useState(false);

  const bulkShowArr = [
    { key: "25_25", text: "25 records visible. 25 pages visibility" },
    { key: "25_400", text: "25 at a time (max400 page)" },
  ];

  const basicArr = [
    { key: "all_filters", text: "All Filters" },
    { key: "technology_5", text: "Technology filter limit to 5" },
  ];

  const csvenrichmenttypeArr = [
    { key: "not_available", text: "Not Available" },
    { key: "upto_100", text: "Upto 100 Rows" },
    { key: "upto_300", text: "Upto 300 Rows" },
    { key: "upto_500", text: "Upto 500 Rows" },
    { key: "upto_10000", text: "Upto 10,000 Rows" },
  ];

  useEffect(() => {
    let values = {};
    values.clientid = id;
    props.fetchPlanDetails(session, values, (res) => {});
  }, [id]);

  const onSubmitForm = (values) => {
    let counter = 0;
    let updateData = {};
    if (
      values.hasOwnProperty("bus_email_credits") &&
      !isNaN(values.bus_email_credits)
    ) {
      updateData.bus_email_credits = values.bus_email_credits;
      counter++;
    }
    if (
      values.hasOwnProperty("personal_email_credits") &&
      !isNaN(values.personal_email_credits)
    ) {
      updateData.personal_email_credits = values.personal_email_credits;
      counter++;
    }

    if (
      values.hasOwnProperty("contactcredit") &&
      !isNaN(values.contactcredit)
    ) {
      updateData.contactcredit = values.contactcredit;
      counter++;
    }
    if (values.hasOwnProperty("apicallcount") && !isNaN(values.apicallcount)) {
      updateData.apicallcount = values.apicallcount;
      counter++;
    }
    if (values.hasOwnProperty("seatcount") && !isNaN(values.seatcount)) {
      updateData.seatcount = values.seatcount;
      counter++;
    }

    if (values.hasOwnProperty("api_rate_min") && !isNaN(values.api_rate_min)) {
      updateData.api_rate_min = values.api_rate_min;
      counter++;
    }

    if (values.hasOwnProperty("api_rate_hr") && !isNaN(values.api_rate_hr)) {
      updateData.api_rate_hr = values.api_rate_hr;
      counter++;
    }

    if (values.hasOwnProperty("api_rate_day") && !isNaN(values.api_rate_day)) {
      updateData.api_rate_day = values.api_rate_day;
      counter++;
    }

    if (values.hasOwnProperty("csv_enr_day") && !isNaN(values.csv_enr_day)) {
      updateData.csv_enr_day = values.csv_enr_day;
      counter++;
    }

    if (
      values.hasOwnProperty("technology_credits") &&
      !isNaN(values.technology_credits)
    ) {
      updateData.technology_credits = values.technology_credits;
      counter++;
    }

    if (values.hasOwnProperty("basicfilters_limits")) {
      updateData.basicfilters_limits = values.basicfilters_limits;
      counter++;
    }

    if (values.hasOwnProperty("bulkshow_types")) {
      updateData.bulkshow_types = values.bulkshow_types;
      counter++;
    }

    if (values.hasOwnProperty("csvenrichment_types")) {
      updateData.csvenrichment_types = values.csvenrichment_types;
      counter++;
    }

    if (values.hasOwnProperty("csvexport_availability")) {
      updateData.csvexport_availability = values.csvexport_availability ? 1 : 0;
      counter++;
    }

    if (values.hasOwnProperty("crm_availablity")) {
      updateData.crm_availablity = values.crm_availablity ? 1 : 0;
      counter++;
    }

    if (values.hasOwnProperty("extension_availability")) {
      updateData.extension_availability = values.extension_availability ? 1 : 0;
      counter++;
    }
    // console.log("updateData", updateData);
    // debugger;

    if (counter > 0) {
      updateData.clientid = id;
      // console.log("updateData", updateData);
      props.updateCredits(session, updateData, (res) => {
        let values = {};
        values.clientid = id;
        props.fetchPlanDetails(session, values, (res) => {
          props.reset();
          Toastify("success", "Credit Updated Successfully");
        });
      });
    }
  };

  const basicOptions = basicArr.map((item) => ({
    value: item.key,
    text: item.text,
  }));

  const bulkOptions = bulkShowArr.map((item) => ({
    value: item.key,
    text: item.text,
  }));

  const csnenrichmentOptions = csvenrichmenttypeArr.map((item) => ({
    value: item.key,
    text: item.text,
  }));

  return (
    <>
      <article className="container px-6 mx-auto grid">
        <div className="grid grid-cols-4">
          <div className="col-span-4">
            <div className="mt-4">
              <div className="flex justify-between mb-3">
                <div className="flex gap-4 items-center">
                  <div>
                    <img
                      src={ArrowBack}
                      className=" cursor-pointer"
                      onClick={() => Navigate("/userdetails/" + id)}
                    />
                  </div>
                  {currentPlanDetails.details ? (
                    <div className="text-base font-semibold">
                      <div>{currentPlanDetails.details.name}</div>
                      <div>{currentPlanDetails.details.email}</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex justify-end items-center gap-5"></div>
              </div>
              {currentPlanDetails.currentplan ? (
                <div className="w-full bg-white border-b border-gray-200 border-solid py-2 mb-2 relative">
                  {/* <div className="text-sm">Current Subscription plan</div> */}
                  <div className="flex items-center justify-between">
                    <div className="flex gap-5 items-center">
                      <div className="flex gap-2 items-center">
                        <div>
                          <img src={PlanIcon} />
                        </div>
                        <div className="font-semibold">
                          {currentPlanDetails.currentplan.planname}
                        </div>
                      </div>
                      <div className="flex gap-2 items-center">
                        <div className="text-sm">
                          ${currentPlanDetails.currentplan.price}{" "}
                          {currentPlanDetails.currentplan.planvalidity == "365"
                            ? "per year"
                            : "per month"}{" "}
                          -{" "}
                        </div>
                        <div className="text-gray-600 text-sm">
                          {(currentPlanDetails.currentplan?.sku ==
                            "custom_trial" ||
                            currentPlanDetails.currentplan?.sku ==
                              "custom_monthly" ||
                            currentPlanDetails.currentplan?.sku ==
                              "custom_yearly") &&
                          currentPlanDetails.currentplan?.autorenew == 1
                            ? "Auto renews on " +
                              currentPlanDetails.currentplan.expirydate
                            : currentPlanDetails.currentplan?.autorenew == 1
                            ? "Auto renews on " +
                              currentPlanDetails.currentplan.nextstartdate
                            : "Expired on " +
                              currentPlanDetails.currentplan.expirydate}
                        </div>
                      </div>
                      <div className="absolute top-0 text-[9px] text-white bg-primary px-0.5">
                        CURRENT PLAN
                      </div>
                      {/* <div className="absolute top-0 text-[9px] text-white bg-primary px-0.5">
                        <div className="p-4 text-sm font-bold">
                          Default Plan Credits
                        </div>
                        <div className="flex justify-center px-4 pb-6">
                          <div className="flex items-center gap-6 text-sm font-semibold">
                            <div>
                              <div>
                                {currentPlanDetails.currentplan.contactcredit}{" "}
                                Phone Number Credits
                              </div>
                              <div></div>
                            </div>
                            <div>
                              <div>
                                {
                                  currentPlanDetails.currentplan
                                    .bus_email_credits
                                }{" "}
                                Business Email Credits
                              </div>
                              <div>
                                {
                                  currentPlanDetails.currentplan
                                    .personal_email_credits
                                }{" "}
                                Personal Email Credits
                              </div>
                            </div>
                            <div>
                              <div>
                                {
                                  currentPlanDetails.currentplan
                                    .availableapicall
                                }{" "}
                                API Keys
                              </div>
                              <div>
                                {currentPlanDetails.currentplan.availableseats}{" "}
                                Seat Available
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="w-full mb-4">
                <div className="flex items-center justify-between">
                  Add additional credits to this plan
                </div>
              </div>
              {currentPlanDetails.availablecredit ? (
                <>
                  <form className="" onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="not-prose relative bg-slate-100 rounded-xl overflow-hidden dark:bg-slate-800/25">
                      <div
                        style={{ backgroundPosition: "10px 10px" }}
                        className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]"
                      ></div>
                      <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden my-8">
                          <table className="border-collapse table-fixed w-full text-sm">
                            <thead>
                              <tr>
                                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                  Benefits
                                </th>

                                <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                  Credit Available
                                </th>
                                <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                  Add Credits
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white dark:bg-slate-800">
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  Phone Number Credits
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {
                                      currentPlanDetails.availablecredit
                                        .contactcredit
                                    }
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="contactcredit"
                                    label=""
                                    component={renderInputField}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  Business Email Credits
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {
                                      currentPlanDetails.availablecredit
                                        .bus_email_credits
                                    }
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="bus_email_credits"
                                    label=""
                                    component={renderInputField}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  Personal Email Credits
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {
                                      currentPlanDetails.availablecredit
                                        .personal_email_credits
                                    }
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="personal_email_credits"
                                    label=""
                                    component={renderInputField}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  API Keys
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .apicallcount
                                      ? currentPlanDetails.availablecredit
                                          .apicallcount
                                      : 0}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="apicallcount"
                                    label=""
                                    component={renderInputField}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  API rate limit per 1 minuite
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .api_rate_min
                                      ? currentPlanDetails.availablecredit
                                          .api_rate_min
                                      : 0}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="api_rate_min"
                                    label=""
                                    component={renderInputField}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  API rate limit per 1 hour
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .api_rate_hr
                                      ? currentPlanDetails.availablecredit
                                          .api_rate_hr
                                      : 0}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="api_rate_hr"
                                    label=""
                                    component={renderInputField}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  API rate limit per day
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .api_rate_day
                                      ? currentPlanDetails.availablecredit
                                          .api_rate_day
                                      : 0}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="api_rate_day"
                                    label=""
                                    component={renderInputField}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  Seat Available
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .seatcount
                                      ? currentPlanDetails.availablecredit
                                          .seatcount
                                      : 0}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="seatcount"
                                    label=""
                                    component={renderInputField}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  CSV enrichment per day
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .csv_enr_day
                                      ? currentPlanDetails.availablecredit
                                          .csv_enr_day
                                      : 0}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="csv_enr_day"
                                    label=""
                                    component={renderInputField}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  Terchnology Credits
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .technology_credits
                                      ? currentPlanDetails.availablecredit
                                          .technology_credits
                                      : 0}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="technology_credits"
                                    label=""
                                    component={renderInputField}
                                  />
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  Add on Credit
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .addoncredit
                                      ? currentPlanDetails.availablecredit
                                          .addoncredit
                                      : 0}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="addoncredit"
                                    label={null}
                                    component={renderSelect}
                                    opts={addoncreditOptions}
                                  />
                                </td>
                              </tr> */}
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  Basic Filters Limit
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .basicfilters_limits
                                      ? basicArr.find(
                                          (item) =>
                                            item.key ===
                                            currentPlanDetails.availablecredit
                                              .basicfilters_limits
                                        )?.text
                                      : "All Filters"}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="basicfilters_limits"
                                    label=""
                                    component={renderSelect}
                                    opts={basicOptions}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  Bulk Show Types
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .bulkshow_types
                                      ? bulkShowArr.find(
                                          (item) =>
                                            item.key ===
                                            currentPlanDetails.availablecredit
                                              .bulkshow_types
                                        )?.text
                                      : "25 records visible. 25 pages visibility"}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="bulkshow_types"
                                    label={null}
                                    component={renderSelect}
                                    opts={bulkOptions}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  CSV Enrichment Types
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .csvenrichment_types
                                      ? csvenrichmenttypeArr.find(
                                          (item) =>
                                            item.key ===
                                            currentPlanDetails.availablecredit
                                              .csvenrichment_types
                                        )?.text
                                      : "Not Available"}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="csvenrichment_types"
                                    label={null}
                                    component={renderSelect}
                                    opts={csnenrichmentOptions}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  Extension Available
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .extension_availability
                                      ? currentPlanDetails.availablecredit
                                          .extension_availability == "1"
                                        ? "Yes"
                                        : "No"
                                      : "No"}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="extension_availability"
                                    label="Extension Available?"
                                    component={renderCheckbox}
                                    type="checkbox"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  Crm Available
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .crm_availablity
                                      ? currentPlanDetails.availablecredit
                                          .crm_availablity == "1"
                                        ? "Yes"
                                        : "No"
                                      : "No"}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="crm_availablity"
                                    label="CRM Available?"
                                    component={renderCheckbox}
                                    type="checkbox"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  CSV Available
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <div className=" bg-gray-200 text-black p-4">
                                    {currentPlanDetails.availablecredit
                                      .csvexport_availability
                                      ? currentPlanDetails.availablecredit
                                          .csvexport_availability == "1"
                                        ? "Yes"
                                        : "No"
                                      : "No"}
                                  </div>
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  <Field
                                    name="csvexport_availability"
                                    label="CSV Export Available?"
                                    component={renderCheckbox}
                                    type="checkbox"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                    </div>
                    <div className="py-6 text-right">
                      <button
                        type="submit"
                        className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
                      >
                        {processLoading ? (
                          <CustomSpinner />
                        ) : (
                          <span>Update</span>
                        )}
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const validate = (values, ownProps) => {
  let errors = {};

  return errors;
};

const mapStateToProps = (state) => {
  let initial = {};

  if (state.currentPlanDetails && state.currentPlanDetails.availablecredit) {
    initial.basicfilters_limits =
      state.currentPlanDetails.availablecredit.basicfilters_limits;
    initial.bulkshow_types =
      state.currentPlanDetails.availablecredit.bulkshow_types;
    initial.csvenrichment_types =
      state.currentPlanDetails.availablecredit.csvenrichment_types;
    initial.extension_availability =
      state.currentPlanDetails.availablecredit.extension_availability;
    initial.crm_availablity =
      state.currentPlanDetails.availablecredit.crm_availablity;
    initial.csvexport_availability =
      state.currentPlanDetails.availablecredit.csvexport_availability;
  }

  console.log("initial", initial);
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    currentPlanDetails: state.currentPlanDetails,
    initialValues: initial,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchPlanDetails, updateCredits }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "CreditForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  })(AddCredit)
);
