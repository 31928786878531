import {ENTERPRISE_PLAN} from "../actions";



export var enterPrisePlan = (state = {}, action) => {
    switch (action.type) {
      case ENTERPRISE_PLAN:
        return action.payload;
        break;
      default:
        return state;
        break;
    }
  };