import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";
import Pagination from "react-js-pagination";
import {
  fetchLogs,
  sendInvoice,
  markAsPaymentDone,
  cancelInvoice,
} from "./actions";
import { CustomSpinner } from "../Common/base-component";
import { Toastify } from "../Common/Toastify";
import Loader from "../Common/Loader";
import ArrowBack from "../../assets/img/icons/arrow-back.svg";
import PlanIcon from "../../assets/img/icons/plan.svg";

const UserDetails = (props) => {
  const { isLoggedIn, session, logList } = props;
  const { id } = useParams();
  const Navigate = useNavigate();
  const Location = useLocation();
  const [pageNumber, setPagenumber] = useState(1);
  const [logType, setLogType] = useState("log");
  const [searchText, setSearchText] = useState(null);
  const [processLoading, setProcessLoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [markLoading, setMarkLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  useEffect(() => {
    let values = {};
    values.clientid = id;
    values.logtype = logType;
    props.fetchLogs(session, values, pageNumber, (res) => {});
  }, [id]);

  const paginationCallback = (pageNumber) => {
    //console.log("pageNumber", pageNumber);
    setPagenumber(pageNumber);
    let values = {};
    values.clientid = id;
    values.logtype = logType;
    props.fetchLogs(session, values, pageNumber, (res) => {});
  };

  const getAllData = () => {
    setProcessLoading(true);
    let values = {};
    values.clientid = id;
    values.logtype = logType;
    values.isexport = true;
    props.fetchLogs(session, values, pageNumber, (res) => {
      setProcessLoading(false);
      if (res.success == 1) {
        downloadSample("csv", res.data, logType);
      }
    });
  };

  const downloadSample = (type, data, logType) => {
    let XLSX = require("xlsx");
    let sample = [];
    if (logType == "credit") {
      data.map((object, index) => {
        let obj = {};
        obj.creditdeducted = Math.abs(object.creditdeducted);
        obj.contacttype = object.contacttype;
        obj.createdon = format(
          new Date(object.createdon),
          "do LLL,yyyy hh:mm a"
        );
        sample.push(obj);
      });
    } else {
      data.map((object, index) => {
        let obj = {};
        obj.name = object.name;
        obj.email = object.email;
        obj.contacttype = object.contacttype;
        obj.revealmethod = object.revealmethod;
        obj.creditdeducted = object.creditdeducted;
        obj.createdon = format(
          new Date(object.createdon),
          "do LLL,yyyy hh:mm a"
        );
        sample.push(obj);
      });
    }

    //console.log("sample updated", sample);
    /* Create a new empty workbook, then add the worksheet */
    let wb = XLSX.utils.book_new();
    // console.log("wb", wb);

    /* Create a worksheet */
    var ws = XLSX.utils.json_to_sheet(sample);
    var sheetName = "logs";

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /* Generate xlsx files */
    if (type === "csv") {
      XLSX.writeFile(wb, "logs.csv");
    } else {
      XLSX.writeFile(wb, "logs.xlsx");
    }
  };

  const _renderCredits = () => {
    return _.map(logList.data, (user, key) => {
      return (
        <tr>
          <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
            {Math.abs(user.creditdeducted)}
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400">
            {user.contacttype}
          </td>
          <td className="  border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400">
            {format(new Date(user.createdon), "do LLL,yyyy hh:mm a")}
          </td>
        </tr>
      );
    });
  };

  const _renderUsers = () => {
    return _.map(logList.data, (user, key) => {
      return (
        <tr>
          <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
            <div>{user.name}</div>
            <div className="truncate">{user.email}</div>
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400">
            {user.contacttype}
          </td>
          <td className="  border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400">
            {user.revealmethod}
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">
            {user.creditdeducted}
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">
            {format(new Date(user.createdon), "do LLL,yyyy hh:mm a")}
          </td>
        </tr>
      );
    });
  };

  const handleDates = (event) => {
    let stdate = document.getElementById("startdate").value;
    let enddate = document.getElementById("enddate").value;
    // console.log('stdate',stdate);
    // console.log('enddate',enddate);
    /* 
        Date validation
        */
    if (_.size(stdate) === 10 && _.size(enddate) === 10) {
      let stdate_replaced = _.replace(stdate, "-", "");
      let enddate_replaced = _.replace(enddate, "-", "");

      stdate_replaced = _.replace(stdate_replaced, "-", "");
      enddate_replaced = _.replace(enddate_replaced, "-", "");

      if (parseInt(stdate_replaced) > parseInt(enddate_replaced)) {
        Toastify("error", "Invalid Date range");
      } else {
        let values = {};
        values.limit = 15;
        values.page = pageNumber;
        values.startdate = stdate;
        values.enddate = enddate;
        values.clientid = id;
        values.logtype = logType;
        props.fetchLogs(session, values, pageNumber, (res) => {});
      }
    } else if (_.size(stdate) === 0 && _.size(enddate) === 0) {
      let values = {};
      values.limit = 15;
      values.page = pageNumber;
      values.clientid = id;
      values.logtype = logType;
      props.fetchUsers(session, values, pageNumber, false, (res) => {});
    } else {
    }
  };
  const handleLog = (e) => {
    let logtype = e.target.value;
    setLogType(logtype);
    let values = {};
    values.clientid = id;
    values.logtype = logtype;
    props.fetchLogs(session, values, pageNumber, (res) => {});
  };

  const sendInvoice = (planid) => {
    setInvoiceLoading(true);
    let values = {};
    values.planid = planid;
    props.sendInvoice(session, values, (res) => {
      setInvoiceLoading(false);
      Toastify("success", "Invoice sent Successfully");
    });
  };

  const markasPaymentDone = (planid) => {
    setMarkLoading(true);
    let values = {};
    values.planid = planid;
    props.markAsPaymentDone(session, values, (res) => {
      setMarkLoading(false);
      Toastify("success", "Plan Successfully started");
    });
  };

  const cancelPendingInvoice = (planid) => {
    console.log("id", id);
    //debugger;
    setCancelLoading(true);
    let values = {};
    values.planid = planid;
    props.cancelInvoice(session, values, (res) => {
      if (res.success) {
        let data = {};
        data.clientid = id;
        data.logtype = logType;
        props.fetchLogs(session, data, 1, (obj) => {
          setCancelLoading(true);
          Toastify("success", "Invoice Cancelled Successfully");
        });
      } else {
        setCancelLoading(false);
        Toastify("error", "Something went wrong");
      }
    });
  };
  //console.log("logList", logList);
  return (
    <>
      <article className="container px-4 mx-auto grid">
        <div className="grid grid-cols-4 ">
          <div className="col-span-4">
            <div className="mt-4">
              <div className="flex justify-between mb-4">
                <div className="flex gap-4 items-center">
                  <div>
                    <img
                      src={ArrowBack}
                      className=" cursor-pointer"
                      onClick={() => Navigate("/userlist")}
                    />
                  </div>
                  {logList.details ? (
                    <div className="text-base flex gap-2">
                      <div className="font-semibold">
                        {logList.details.name}
                      </div>
                      <div className="text-gray-500">
                        {`(${logList.details.email})`}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex justify-end items-center gap-5">
                  {logList.upgrade ? (
                    <div className="flex justify-end items-center">
                      {logList.upgrade.payment_status == "pending" &&
                      logList.upgrade.paymode == "offline" ? (
                        <button
                          type="button"
                          className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
                          onClick={() =>
                            markasPaymentDone(logList?.upgrade?.planid)
                          }
                        >
                          {invoiceLoading ? (
                            <CustomSpinner />
                          ) : (
                            <span>Mark as Payment Done</span>
                          )}
                        </button>
                      ) : logList.upgrade.payment_status == "pending" &&
                        logList.upgrade.paymode == "online" ? (
                        <div className="text-right">
                          <div className="flex gap-2 justify-end text-base font-semibold">
                            <div>Invoice Generated</div>
                            <div> - </div>
                            <div>
                              Payment {logList?.upgrade?.payment_status}
                            </div>
                          </div>
                          <div className="flex gap-4 mt-4">
                            <div>
                              <button
                                type="button"
                                className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
                                onClick={() =>
                                  cancelPendingInvoice(logList?.upgrade?.planid)
                                }
                              >
                                {cancelLoading ? (
                                  <CustomSpinner />
                                ) : (
                                  <span>Cancel</span>
                                )}
                              </button>
                            </div>
                            <div>
                              <button
                                type="button"
                                className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
                                onClick={() =>
                                  sendInvoice(logList?.upgrade?.planid)
                                }
                              >
                                {invoiceLoading ? (
                                  <CustomSpinner />
                                ) : (
                                  <span>Resend Invoice</span>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : logList.isenterpriseplan ? (
                    ""
                  ) : (
                    <div className="flex justify-end items-center">
                      <button
                        type="button"
                        className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
                        onClick={() => Navigate("/updateplan/" + id)}
                      >
                        Upgrade to Enterprise
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {logList.currentplan ? (
                <div className="w-full bg-white border-b border-gray-200 border-solid py-2 mb-2 relative">
                  {/* <div className="text-sm">Current Subscription plan</div> */}
                  <div className="flex items-center justify-between">
                    <div className="flex gap-5 items-center">
                      <div className="flex gap-2 items-center">
                        <div>
                          <img src={PlanIcon} />
                        </div>
                        <div className="font-semibold">
                          {logList.currentplan.planname}
                        </div>
                      </div>
                      <div className="flex gap-2 items-center">
                        <div className="text-sm">
                          ${logList.currentplan.price}{" "}
                          {logList.currentplan.planvalidity == "365"
                            ? "per year"
                            : "per month"}{" "}
                          -{" "}
                        </div>
                        <div className="text-gray-600 text-sm">
                          {(logList.currentplan?.sku == "custom_trial" ||
                            logList.currentplan?.sku == "custom_monthly" ||
                            logList.currentplan?.sku == "custom_yearly") &&
                          logList.currentplan?.autorenew == 1
                            ? "Auto renews on " + logList.currentplan.expirydate
                            : logList.currentplan?.downgrade_date
                            ? "Downgrade starts on " +
                              logList.currentplan.downgrade_date
                            : logList.currentplan?.autorenew == 1
                            ? "Auto renews on " +
                              logList.currentplan.nextstartdate
                            : "Expired on " + logList.currentplan.expirydate}
                        </div>
                      </div>
                      <div className="absolute top-0 text-[9px] text-white bg-primary px-0.5">
                        CURRENT PLAN
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="px-4 py-1  leading-5 transition-colors duration-150 border border-transparent rounded-md bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
                        onClick={() => Navigate("/addcredit/" + id)}
                      >
                        Add Credit
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="w-full mb-4">
                <div className="flex items-center justify-between">
                  <div>
                    <select
                      onChange={(e) => handleLog(e)}
                      className="border border-gray-300 border-solid rounded-md text-sm shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight "
                    >
                      <option
                        value="log"
                        {...(logType == "log" ? "SELECTED" : "")}
                      >
                        User Logs
                      </option>
                      <option
                        value="credit"
                        {...(logType == "credit" ? "SELECTED" : "")}
                      >
                        Credits
                      </option>
                    </select>
                  </div>
                  <div className="flex gap-5">
                    <div className="flex items-center gap-2">
                      <div className="text-sm text-gray-800">Start Date - </div>
                      <div>
                        <input
                          className="appearance-none px-3 py-2 border border-gray-300 border-solid rounded-md text-sm shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight  w-40"
                          type="date"
                          id="startdate"
                          name="startdate"
                          onChange={(e) => handleDates(e)}
                          placeholder="Start Date"
                        />
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="text-sm text-gray-800">End Date - </div>
                      <div>
                        <input
                          className="appearance-none  px-3 py-2 border border-gray-300 border-solid rounded-md text-sm shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight sm:text-sm w-40"
                          type="date"
                          id="enddate"
                          name="enddate"
                          onChange={(e) => handleDates(e)}
                          placeholder="End Date"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-kipploborder rounded-lg bg-white text-kipplocolor focus:outline-none"
                      onClick={() => getAllData()}
                    >
                      Export log
                    </button>
                  </div>
                </div>
              </div>
              {logList.data ? (
                logList.data.length > 0 ? (
                  <>
                    <div className="not-prose relative bg-slate-100 rounded-xl overflow-hidden dark:bg-slate-800/25">
                      <div
                        style={{ backgroundPosition: "10px 10px" }}
                        className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]"
                      ></div>
                      <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden my-8">
                          <table className="border-collapse table-fixed w-full text-sm">
                            <thead>
                              {logType == "credit" ? (
                                <tr>
                                  <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                    Credit Added
                                  </th>

                                  <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                    Benefit Area
                                  </th>
                                  <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                    Added on
                                  </th>
                                </tr>
                              ) : (
                                <tr>
                                  <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                    Revealed Details
                                  </th>

                                  <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                    Reveal Area
                                  </th>
                                  <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                    Reveal In
                                  </th>
                                  <th className="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                    Credit Deducted
                                  </th>
                                  <th className="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                    Date
                                  </th>
                                </tr>
                              )}
                            </thead>
                            <tbody className="bg-white dark:bg-slate-800">
                              {logType == "credit"
                                ? _renderCredits()
                                : _renderUsers()}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                    </div>
                    <div className="mt-4">
                      <Pagination
                        activePage={pageNumber}
                        itemsCountPerPage={10}
                        totalItemsCount={logList.totalcount}
                        pageRangeDisplayed={10}
                        onChange={(e) => paginationCallback(e)}
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center text-base text-gray-500 ">
                    No {logType == "credit" ? "Credit(s)" : "Log(s)"} Found
                  </div>
                )
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    logList: state.logListReducers,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchLogs, sendInvoice, markAsPaymentDone, cancelInvoice },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
