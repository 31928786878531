import { API_KEY } from "./constant";

// export const getHeaders = (session) => ({
export const getHeaders = (session) => ({
  headers: {
    "X-ApiFlo-API-Key": API_KEY,
    contentType: "application/json; charset=utf-8",
    ...(session.session_token && session.session_token != ""
      ? { "X-ApiFlo-Session-Token": session.session_token }
      : {}),
  },
});

export var getMultiPartHeaders = (session) => ({
  headers: {
    "X-ApiFlo-Api-Key": session.apiKey,
    "X-ApiFlo-Session-Token": session.session_token,
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export var getHeadersWithParams = (session, params) => ({
  headers: {
    "X-ApiFlo-Api-Key": session.apiKey,
    "X-ApiFlo-Session-Token": session.session_token,
    "Content-Type": "multipart/form-data",
  },
  params: params,
});

export var getHeadersMultiPart = (session) => ({
  headers: {
    "X-ApiFlo-Api-Key": session.apiKey,
    "X-ApiFlo-Session-Token": session.session_token,
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
  },
});

export var getImageHeaders = (session) => ({
  headers: {
    "X-ApiFlo-Api-Key": session.apiKey,
    "X-ApiFlo-Session-Token": session.session_token,
    contentType: "application/json; charset=utf-8",
  },
});

export var getExtraImageHeaders = (session, filename) => ({
  headers: {
    "X-ApiFlo-Api-Key": session.apiKey,
    "X-ApiFlo-Session-Token": session.session_token,
    contentType: "application/json; charset=utf-8",
    "X-File-Name": filename,
  },
});

export const insertElementAtPosition = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
