import axios from "axios";
import { reset } from "redux-form";

// constants
import {
  API_KEY,
  INSTANCE_URL,
  S3_POST_URL,
  TABLE_URL,
  SCRIPT_URL,
} from "../components/Common/constant";
import {
  deleteRequest,
  multipartFileUpload,
  postRequest,
} from "../components/Common/network-call";
import LocalStrings from "../languages/LocalStrings";
import { getHeaders } from "../components/Common/common-utils";

export var ACTION_LOGOUT = "ACTION_LOGOUT";
export const HM_PARTNER_LOGIN = "HM_PARTNER_LOGIN";
export const HM_PARTNER_LOGOUT = "HM_PARTNER_LOGOUT";
export const SET_SESSION_TOKEN = "SET_SESSION_TOKEN";
export const UNSET_SESSION_TOKEN = "UNSET_SESSION_TOKEN";
export const DEVICE_SIZE_REDUCER = "DEVICE_SIZE_REDUCER";
export const SET_COMMON_MODAL_DETAILS = "SET_COMMON_MODAL_DETAILS";
export const SET_COMMON_EDIT_MODAL_DETAILS = "SET_COMMON_EDIT_MODAL_DETAILS";
export const SET_FULL_PAGE_LOADER = "SET_FULL_PAGE_LOADER";
export const PARTNER_DETAILS = "PARTNER_DETAILS";
export const SET_CODE_ADD_MODAL_DETAILS = "SET_CODE_ADD_MODAL_DETAILS";

export function checkSubdomain(session, seocode, callback) {
  var seocode = "loreal";
  let url = `${INSTANCE_URL}/fetchsubdomain?subdomain=${seocode}`;
  let urlResponse = axios.get(url, {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  });

  return (dispatch) => {
    urlResponse
      .then(({ data }) => {
        if (
          data.code == 200 &&
          data.data &&
          data.data.idpartner &&
          data.data.subdomain
        ) {
          dispatch({
            type: PARTNER_DETAILS,
            payload: data.data,
          });
          dispatch({
            type: SET_SESSION_TOKEN,
            payload: { subdomain: data.data.subdomain },
          });
          callback({ success: 1, data: data.data });
        } else {
          callback({ success: 0 });
        }
      })
      .catch((error) => {
        callback({ success: 0, _message: error });
      });
  };
}

// ============= ACTIONS TO SET REDUX VALUES
export const mainLogin = (values, callback) => {
  let config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-ApiFlo-Api-Key": API_KEY,
    },
  };

  let loginUrl = `${INSTANCE_URL}/api/v2/user/session`;
  let loginresponse = axios.post(loginUrl, values, config);

  return (dispatch) => {
    loginresponse
      .then(({ data }) => {
        if (data.session_token) {
          if (data.isactive == 1 && data.issuperadmin == 1) {
            dispatch({ type: SET_SESSION_TOKEN, payload: data });
            dispatch({ type: HM_PARTNER_LOGIN, payload: true });
            callback({ success: 1 });
          } else if (data.isactive == 0) {
            callback({
              success: 2,
              message: LocalStrings.validation_user_not_active,
            });
          } else if (data.issuperadmin == 0) {
            callback({
              success: 3,
              message: LocalStrings.validation_user_not_sa,
            });
          }
        } else {
          callback({
            success: 0,
            message: LocalStrings.validation_user_wrong_credentials,
          });
        }
      })
      .catch((error) => {
        callback({ success: 0, _message: error });
      });
  };
};

export const setProgram = (programid) => {
  return (dispatch) => {
    dispatch({ type: SET_SESSION_TOKEN, payload: { programid: programid } });
  };
};

export const mainLogout = (session, callback) => {
  return (dispatch) => {
    dispatch({ type: UNSET_SESSION_TOKEN, payload: false });
    dispatch({ type: HM_PARTNER_LOGIN, payload: false });
    callback({ success: 1 });
  };
};

export function logout(session) {
  return (dispatch) => {
    dispatch({ type: ACTION_LOGOUT, payload: false });
    dispatch({ type: "RESET" });
  };
}

export const setAddCodeModalDetails = (values) => {
  return (dispatch) => {
    dispatch({ type: SET_CODE_ADD_MODAL_DETAILS, payload: values });
  };
};

export const deviceSize = (value) => {
  return (dispatch) => {
    dispatch({ type: DEVICE_SIZE_REDUCER, payload: value });
  };
};

export const setCommonModalDetails = (values) => {
  return (dispatch) => {
    dispatch({ type: SET_COMMON_MODAL_DETAILS, payload: values });
  };
};

export const setCommonEditModalDetails = (values) => {
  return (dispatch) => {
    dispatch({ type: SET_COMMON_EDIT_MODAL_DETAILS, payload: values });
  };
};

// ============== ACTIONS WITH API CALL
export const uploadFilesAsMultiPart = (
  session,
  dataMedia,
  filename,
  callback
) => {
  let uploadUrl = `${S3_POST_URL}/${filename}`;

  return (dispatch) => {
    multipartFileUpload(
      uploadUrl,
      dataMedia,
      session,
      dispatch,
      (res) => {
        if (res.name) callback({ success: 1, data: res });
        else callback({ success: 0, message: "" });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};

export const deleteS3Files = (session, filePath, callback) => {
  let deleteUrl = `${S3_POST_URL}/${filePath}`;

  return (dispatch) => {
    deleteRequest(
      deleteUrl,
      session,
      dispatch,
      (res) => {
        callback({
          success: 1,
          data: res,
        });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};

export const resetEditModalForm = () => {
  return (dispatch) => dispatch(reset("CommonModalForm"));
};

export const setFullPageLoader = (payload) => {
  return (dispatch) => dispatch({ type: SET_FULL_PAGE_LOADER, payload });
};

export const sendPushNotification = (session, values, callback) => {
  let postUrl = `${TABLE_URL}/notifications`,
    postBody = { resource: [values] };

  return (dispatch) => {
    postRequest(
      postUrl,
      postBody,
      session,
      dispatch,
      (res) => {
        callback({ success: 1, data: res });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};
