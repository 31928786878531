import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";
import { fetchEnterpriseData } from "./actions";
import { setAddCodeModalDetails } from "../../actions";
import Loader from "../Common/Loader";

const Plan = (props) => {
  const { isLoggedIn, session, enterPrisePlan } = props;
  const Navigate = useNavigate();
  const Location = useLocation();

  useEffect(() => {
    props.fetchEnterpriseData(session, (res) => {});
  }, []);

  const editModal = (plandetails) => {
    props.setAddCodeModalDetails({
      show: true,
      calledFrom: "plan",
      data: plandetails,
    });
  };

  const _renderCode = () => {
    return _.map(enterPrisePlan.data, (code, key) => {
      let feature = JSON.parse(code.new_features_json);

      return (
        <tr>
          <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500 text-left pl-4">
            <div>
              {code.sku == "custom_yearly"
                ? code.name + " Yearly"
                : code.sku == "custom_monthly"
                ? code.name + " Monthly"
                : code.name}
            </div>
            <div className="text-sm text-gray-500">
              Price - {code.price != "-1" ? "$" + code.price : ""}
            </div>
            <div className="text-sm text-gray-500">
              Validity - {code.validity}
            </div>
          </td>

          <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
            {code.contactcredit}
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
            {code.personal_email_credits}
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
            {code.bus_email_credits}
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
            {code.technology_credits}
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
            <div className="">
              Per Min - {code.api_rate_min ? code.api_rate_min : 0}
            </div>
            <div className="">
              Per Hr - {code.api_rate_hr ? code.api_rate_hr : 0}
            </div>
            <div className="">
              Per Day - {code.api_rate_day ? code.api_rate_day : 0}
            </div>
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
            {code.csv_enr_day}
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500 justify-center flex">
            <a
              className="text-kipplocolor cursor-pointer"
              onClick={() => editModal(code)}
            >
              Edit
            </a>
          </td>
        </tr>
      );
    });
  };

  //console.log('enterPrisePlan',enterPrisePlan);

  return (
    <>
      <article className="container px-4 mx-auto grid">
        <div className="grid grid-cols-4 mt-6">
          <div className="col-span-4">
            <div>
              {enterPrisePlan.data ? (
                enterPrisePlan.data.length > 0 ? (
                  <>
                    <div className="not-prose relative bg-slate-100 rounded-xl overflow-hidden">
                      <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden">
                          <table className="border-collapse table-fixed w-full text-[16px]">
                            <thead>
                              <tr>
                                <th className="border-b font-medium p-1.5 text-left pl-4">
                                  Name
                                </th>

                                <th className="border-b font-medium p-1.5 text-left">
                                  Contact Credits
                                </th>
                                <th className="border-b font-medium p-1.5  text-left">
                                  Personal Email Credits
                                </th>
                                <th className="border-b font-medium p-1.5  text-left">
                                  Business Email Credits
                                </th>
                                <th className="border-b font-medium p-1.5  text-left">
                                  Technology Credits
                                </th>
                                <th className="border-b font-medium p-1.5  text-left">
                                  API Rate
                                </th>
                                <th className="border-b font-medium p-1.5  text-left">
                                  CSV Enrichment
                                </th>
                                <th className="border-b font-medium p-1.5 text-left" />
                              </tr>
                            </thead>
                            <tbody className="bg-white">{_renderCode()}</tbody>
                          </table>
                        </div>
                      </div>
                      <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center text-base ">
                    No Plan Found
                  </div>
                )
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    enterPrisePlan: state.enterPrisePlan,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchEnterpriseData, setAddCodeModalDetails }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
