import { USER_LIST, PLAN_DETAILS, LOGS_LIST } from "../actions";

export var userList = (state = {}, action) => {
  switch (action.type) {
    case USER_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var logListReducers = (state = {}, action) => {
  switch (action.type) {
    case LOGS_LIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var currentPlanDetails = (state = {}, action) => {
  switch (action.type) {
    case PLAN_DETAILS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
