import _ from "lodash";
import { INSTANCE_URL, TABLE_URL } from "../../Common/constant";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../Common/network-call";
import { reset } from "redux-form";

export var USER_LIST = "USER_LIST";
export var LOGS_LIST = "LOGS_LIST";
export var PLAN_DETAILS = "PLAN_DETAILS";

export function fetchUsers(
  session,
  searchtext = null,
  pageCount = 1,
  isexport = false,
  callback
) {
  let values = {};
  values.limit = 10;
  values.page = pageCount;
  if (searchtext) {
    values.searchtext = searchtext;
  }
  if (isexport) {
    values.isexport = true;
  }
  var url = `${INSTANCE_URL}/api/v2/superadmin-script/fetchuser`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (res) => {
        dispatch({
          type: USER_LIST,
          payload: { data: res.data, totalcount: res.totalcount },
        });
        callback({ success: 1, data: res.data });
      },
      (error) => {}
    );
  };
}

export function fetchLogs(session, values, pageCount = 1, callback) {
  values.limit = 10;
  values.page = pageCount;
  var url = `${INSTANCE_URL}/api/v2/superadmin-script/fetchlog`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (res) => {
        dispatch({
          type: LOGS_LIST,
          payload: {
            data: res.data.logresponse,
            totalcount: res.data.totalcount,
            details: res.data.details,
            currentplan: res.data.currentplan,
            upgrade: res.data.upgrade,
            isenterpriseplan: res.data.isenterpriseplan,
          },
        });
        callback({ success: 1, data: res.data.logresponse });
      },
      (error) => {}
    );
  };
}

export function fetchPlanDetails(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-script/fetchdetails`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (res) => {
        dispatch({
          type: PLAN_DETAILS,
          payload: {
            details: res.data.details,
            currentplan: res.data.currentplan,
            availablecredit: res.data.availablecredit,
            enterprise: res.data.enterprise,
          },
        });
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function updateCredits(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-script/updatecredit`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (res) => {
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function updatePlan(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-script/updateplan`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (res) => {
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function sendInvoice(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-script/generate_invoice`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (res) => {
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function markAsPaymentDone(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-script/markasdone`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (res) => {
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function cancelInvoice(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-script/cancelinvoice`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (res) => {
        callback({ success: res.success });
      },
      (error) => {}
    );
  };
}
