import { REPORTED_CONTACTLIST } from "../actions";

export var reportedContactList = (state = {}, action) => {
  switch (action.type) {
    case REPORTED_CONTACTLIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
