export const LOGIN_EMAIL = "superadmin@kipplo.com";
export const LOGIN_PASSOWRD = "Teks@1234";
export const API_KEY =
  "b1a8fffca04edbc42c088a0fbdb453ccabf387c0727400d9d2153a9bc403920a";
export const S3_ACCESS_KEY =
  "b5cb82af7b5d4130f36149f90aa2746782e59a872ac70454ac188743cb55b0ba";
export const INSTANCE_URL = "https://dev1.kipplodev.com";

export const TABLE_URL = `${INSTANCE_URL}/api/v2/thomsondata/_table`;
export const KIPPLO_TABLE_URL = `${INSTANCE_URL}/api/v2/kipplodb/_table`;
export const SCRIPT_URL = `${INSTANCE_URL}/api/v2`;
export const FILES_URL = `${SCRIPT_URL}/files`;
export const S3_POST_URL = `${SCRIPT_URL}/files`;
export const DATE_FORMAT = "yyyy-MM-dd"; // dd for date-fns
