import _ from "lodash";
import { INSTANCE_URL, TABLE_URL } from "../../Common/constant";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../Common/network-call";
import { reset } from "redux-form";

export var REPORTED_CONTACTLIST = "REPORTED_CONTACTLIST";

export function fetchReportedList(
  session,
  appuserid = null,
  pageCount = 1,
  callback
) {
  let values = {};
  values.limit = 10;
  values.page = pageCount;
  if (appuserid) {
    values.appuserid = appuserid;
  }
  var url = `${INSTANCE_URL}/api/v2/superadmin-script/reportedcontacts`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (res) => {
        dispatch({
          type: REPORTED_CONTACTLIST,
          payload: { data: res.data, totalcount: res.totalcount },
        });
        callback({ success: 1, data: res.data });
      },
      (error) => {}
    );
  };
}

export function updateStatus(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/superadmin-script/updatestatus`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (res) => {
        callback({ success: 1, data: res.data });
      },
      (error) => {}
    );
  };
}
