import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";
import Pagination from "react-js-pagination";
import { fetchReportedList, updateStatus } from "./actions";
import { setAddCodeModalDetails } from "../../actions";
import { CustomSpinner } from "../Common/base-component";
import Loader from "../Common/Loader";
import { toTitleCase } from "../Common/common-utils";

const ContactList = (props) => {
  const { isLoggedIn, session, reportedContactList } = props;
  const [pageNumber, setPagenumber] = useState(1);
  const [updateid, setUpdateid] = useState(null);
  const [appuserid, setAppuserid] = useState(null);
  const Navigate = useNavigate();
  const Location = useLocation();

  useEffect(() => {
    var url_string = window.location.href;
    var query = url_string.split("?")[1];
    var params = {};
    if (query) {
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
    }
    console.log("params", params);
    let appuserid = null;
    if (params?.id) {
      appuserid = params?.id;
      setAppuserid(appuserid);
    }
    props.fetchReportedList(session, appuserid, 1, (res) => {});
  }, []);

  const paginationCallback = (pageNumber) => {
    //console.log("pageNumber", pageNumber);
    setPagenumber(pageNumber);
    props.fetchReportedList(session, appuserid, pageNumber, false, (res) => {});
  };

  const approveModal = (userdetails) => {
    userdetails.owner = appuserid != null ? "seluser" : "all";
    props.setAddCodeModalDetails({
      show: true,
      calledFrom: "contactmetaapprove",
      contacttype: userdetails.contacttype,
      data: userdetails,
    });
  };

  const rejectContact = (id) => {
    setUpdateid(id);
    let values = {};
    values.id = id;
    values.status = "rejected";
    props.updateStatus(session, values, (feed) => {
      props.fetchReportedList(session, (res) => {
        setUpdateid(null);
      });
    });
  };

  const _renderList = () => {
    return _.map(reportedContactList.data, (user, key) => {
      let cssclass =
        user.status == "approved"
          ? "text-approved"
          : user.status == "rejected"
          ? "text-rejected"
          : "text-pending";

      const reporttype =
        user.contacttype === "businessemail"
          ? "Work Email"
          : user.contacttype === "personalemail"
          ? "Secondary Email"
          : "Direct Dial";

      return (
        <tr>
          <td className="border-b border-slate-100 p-1.5 text-left pl-4">
            <div>{user.name}</div>
            <div className="text-gray-500">{user.email}</div>
          </td>
          <td className="border-b border-slate-100 p-1.5 text-left pl-4">
            {user.contact_name}
          </td>
          <td className="border-b border-slate-100 p-1.5">{reporttype}</td>
          <td className="border-b border-slate-100 p-1.5">
            <div className="text-primary">
              {user.downvotereason ? toTitleCase(user.downvotereason) : ""}
            </div>
            <div className="flex gap-1 text-xs">
              <div>Info - </div>
              <div className="text-gray-500">{user.reasonnote}</div>
            </div>
          </td>
          <td className="border-b border-slate-100 p-1.5">
            <span className={cssclass}>{toTitleCase(user.status)}</span>
          </td>

          <td className="border-b border-slate-100 p-1.5">
            {user.status == "pending" ? (
              <div className="flex gap-4 font-medium">
                <a
                  className="cursor-pointer text-kipplocolor"
                  onClick={() => approveModal(user)}
                >
                  Approve
                </a>
                {updateid == user.id ? (
                  <CustomSpinner />
                ) : (
                  <a
                    className="cursor-pointer text-danger"
                    onClick={() => rejectContact(user.id)}
                  >
                    Reject
                  </a>
                )}
              </div>
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    });
  };
  return (
    <>
      <article className="container px-6 mx-auto grid">
        <div className="grid grid-cols-4 mt-4">
          <div className="col-span-4">
            <div>
              <div className="flex gap-4 pb-4">
                {appuserid ? (
                  <div>
                    <a
                      onClick={() => Navigate("/userlist")}
                      className=" text-kipplocolor cursor-pointer"
                    >
                      Back to Users
                    </a>
                  </div>
                ) : (
                  ""
                )}
                <div className="text-base font-semibold">Reported Contacts</div>
              </div>
              {reportedContactList.data ? (
                reportedContactList.data.length > 0 ? (
                  <>
                    <div className="not-prose relative bg-slate-100 rounded-xl overflow-hidden dark:bg-slate-800/25">
                      <div
                        style={{ backgroundPosition: "10px 10px" }}
                        className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]"
                      ></div>
                      <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden my-2">
                          <table className="border-collapse table-fixed w-full text-[16px]">
                            <thead>
                              <tr>
                                <th className="border-b font-medium p-1.5 text-left pl-4">
                                  Reported By
                                </th>
                                <th className="border-b font-medium text-left p-1.5 text-left pl-4">
                                  Contact Name
                                </th>
                                <th className="border-b font-medium text-left p-1.5 ">
                                  Report Type
                                </th>
                                <th className="border-b font-medium text-left p-1.5 ">
                                  Reason
                                </th>
                                <th className="border-b font-medium text-left p-1.5 ">
                                  Status
                                </th>
                                <th className="border-b font-medium text-left p-1.5 " />
                              </tr>
                            </thead>
                            <tbody className="bg-white">{_renderList()}</tbody>
                          </table>
                        </div>
                      </div>
                      <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                    </div>
                    <div className="mt-4">
                      <Pagination
                        activePage={pageNumber}
                        itemsCountPerPage={10}
                        totalItemsCount={reportedContactList.totalcount}
                        pageRangeDisplayed={10}
                        onChange={(e) => paginationCallback(e)}
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center text-base h-screen text-gray-500">
                    No Contact(s) Found
                  </div>
                )
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    reportedContactList: state.reportedContactList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchReportedList, updateStatus, setAddCodeModalDetails },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
