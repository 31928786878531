import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm, FieldArray, getFormValues } from "redux-form";
import _ from "lodash";
import { format, addDays, subDays } from "date-fns";
import Pagination from "react-js-pagination";
import { fetchPlanDetails, updatePlan, sendInvoice } from "./actions";
import { fetchEnterpriseData } from "../plan/actions/";
import {
  CustomSpinner,
  renderInputField,
  renderDatePickerField,
  renderCheckbox,
  renderSelect,
} from "../Common/base-component";
import { Toastify } from "../Common/Toastify";
import LocalStrings from "../../languages/LocalStrings";
import Loader from "../Common/Loader";
import ArrowBack from "../../assets/img/icons/arrow-back.svg";
import PlanIcon from "../../assets/img/icons/plan.svg";
import { DATE_FORMAT } from "../Common/constant";

const UpdatePlan = (props) => {
  const {
    isLoggedIn,
    session,
    currentPlanDetails,
    handleSubmit,
    enterPrisePlan,
    formValues,
  } = props;
  const { id } = useParams();
  const Navigate = useNavigate();
  const Location = useLocation();
  const [pageNumber, setPagenumber] = useState(1);
  const [logType, setLogType] = useState("log");
  const [searchText, setSearchText] = useState(null);
  const [processLoading, setProcessLoading] = useState(false);
  const [plan, setPlan] = useState(null);

  const bulkShowArr = [
    { key: "25_25", text: "25 records visible. 25 pages visibility" },
    { key: "25_400", text: "25 at a time (max400 page)" },
  ];

  const basicArr = [
    { key: "all_filters", text: "All Filters" },
    { key: "technology_5", text: "Technology filter limit to 5" },
  ];

  const csvenrichmenttypeArr = [
    { key: "not_available", text: "Not Available" },
    { key: "upto_100", text: "Upto 100 Rows" },
    { key: "upto_300", text: "Upto 300 Rows" },
    { key: "upto_500", text: "Upto 500 Rows" },
    { key: "upto_10000", text: "Upto 10,000 Rows" },
  ];

  useEffect(() => {
    let values = {};
    values.clientid = id;
    props.fetchPlanDetails(session, values, (res) => {});
    props.fetchEnterpriseData(session, (res) => {});
  }, [id]);

  const onSubmitForm = (values) => {
    setProcessLoading(true);
    values.clientid = id;
    values.autorenew = values.autorenew ? 1 : 0;
    if (typeof values.startdate === "object") {
      values.startdate = format(values.startdate, DATE_FORMAT);
    }
    if (typeof values.enddate === "object") {
      values.enddate = format(values.enddate, DATE_FORMAT);
    }

    props.updatePlan(session, values, (res) => {
      props.fetchPlanDetails(session, values, (res) => {
        setProcessLoading(false);
        Toastify("success", "Plan Upgraded Successfully");
      });
    });
  };

  const sendInvoice = (planid) => {
    setProcessLoading(true);
    let values = {};
    values.planid = planid;
    props.sendInvoice(session, values, (res) => {
      setProcessLoading(false);
      Toastify("success", "Invoice sent Successfully");
    });
  };

  const handlePlan = (e) => {
    let sku = e.target.value;
    let validity = 30;
    if (sku == "custom_monthly") {
      validity = 30;

      let today = new Date();
      console.log("today", today);
      let stdate = format(today, DATE_FORMAT);
      console.log("stdate", stdate);
      props.autofill("startdate", stdate);

      const result = addDays(new Date(today), validity);
      let enddate = format(result, DATE_FORMAT);
      props.autofill("enddate", enddate);
    } else if (sku == "custom_yearly") {
      validity = 365;
      let today = new Date();
      let stdate = format(today, DATE_FORMAT);
      props.autofill("startdate", stdate);

      const result = addDays(new Date(today), validity);
      let enddate = format(result, DATE_FORMAT);
      props.autofill("enddate", enddate);
    } else {
      validity = 365;
      let today = new Date();
      let stdate = format(today, DATE_FORMAT);
      props.autofill("startdate", stdate);
      props.autofill("enddate", "");
    }
    setPlan(sku);
  };

  const handleDates = (e, field) => {
    let datestr = format(e, DATE_FORMAT);
    let planid = formValues.planid;
    let selectedPlan = _.filter(enterPrisePlan.data, (obj) => {
      return obj.planid == planid;
    });
    let validity = 30;
    if (selectedPlan.length > 0) {
      validity = selectedPlan[0].validity;
    }

    if (field == "subscribedon") {
      const result = addDays(new Date(datestr), validity);
      let enddate = format(result, DATE_FORMAT);
      props.autofill("expirydate", enddate);
    } else {
      const result = subDays(new Date(datestr), validity);
      let stdate = format(result, DATE_FORMAT);
      props.autofill("subscribedon", stdate);
    }
  };

  let options = [];
  options.push({
    value: "",
    text: "Select Plan",
  });
  options.push({
    value: "custom",
    text: "Custom",
  });
  options.push({
    value: "custom_monthly",
    text: "Custom Monthly",
  });
  options.push({
    value: "custom_yearly",
    text: "Custom Yearly",
  });

  let mode_options = [];
  mode_options.push({
    value: "online",
    text: "Online",
  });
  mode_options.push({
    value: "offline",
    text: "Offline",
  });

  const basicOptions = basicArr.map((item) => ({
    value: item.key,
    text: item.text,
  }));

  const bulkOptions = bulkShowArr.map((item) => ({
    value: item.key,
    text: item.text,
  }));

  const csnenrichmentOptions = csvenrichmenttypeArr.map((item) => ({
    value: item.key,
    text: item.text,
  }));

  //console.log("currentPlanDetails", currentPlanDetails);
  return (
    <>
      <article className="container px-6 mx-auto grid">
        <div className="grid grid-cols-4 mt-6">
          <div className="col-span-4">
            <div className="mt-4 -mb-3">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-0 mb-3">
                <div className=" flex gap-4  pb-4">
                  <div>
                    <img
                      src={ArrowBack}
                      className=" cursor-pointer"
                      onClick={() => Navigate("/userdetails/" + id)}
                    />
                  </div>
                  <div className="text-base font-semibold">
                    Enterprise Plan Details
                  </div>
                </div>
                <div className="flex justify-end items-center gap-5"></div>
              </div>
              <div className=" text-sm font-semibold pb-4">
                {currentPlanDetails.details ? (
                  <div className="text-base font-semibold">
                    {currentPlanDetails.details.name}'s Enterprise plan
                  </div>
                ) : (
                  ""
                )}
              </div>
              <form className="" onSubmit={handleSubmit(onSubmitForm)}>
                <>
                  <div className="w-full bg-white rounded shadow-xl mb-5 p-6 space-y-3">
                    <div className="grid grid-cols-1 md:grid-cols-3  xl:grid-cols-5 gap-2">
                      <div className="flex items-center">
                        <Field
                          name="price"
                          label="Price"
                          component={renderInputField}
                          mandatory="true"
                        />
                      </div>
                      <div className="flex items-center">
                        <Field
                          name="sku"
                          label="Plan"
                          component={renderSelect}
                          opts={options}
                          onChange={(e) => handlePlan(e)}
                        />
                      </div>
                      {plan == "custom_yearly" || plan == "custom_monthly" ? (
                        <div className="flex items-center">
                          <Field
                            name="payment_mode"
                            label="Payment Mode"
                            component={renderSelect}
                            opts={mode_options}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {/* </div>

                    <div className="flex gap-5"> */}
                      <div className="flex items-center">
                        <Field
                          name="startdate"
                          label="Start Date"
                          component={renderDatePickerField}
                          //onChange={(e) => handleDates(e, "subscribedon")}
                        />
                      </div>
                      <div className="flex items-center">
                        <Field
                          name="enddate"
                          label="End Date"
                          component={renderDatePickerField}
                          //onChange={(e) => handleDates(e, "expirydate")}
                        />
                      </div>
                    </div>
                    <div className="flex gap-5">
                      <Field
                        name="autorenew"
                        label="Auto Renewal"
                        component={renderCheckbox}
                        type="checkbox"
                      />
                    </div>
                  </div>
                </>

                <div className="w-full mb-4">
                  <div className="flex items-center justify-between">
                    Set the benfit limits for this enterprise plan
                  </div>
                </div>
                <>
                  <div className="not-prose relative bg-slate-100 rounded-xl overflow-hidden dark:bg-slate-800/25">
                    <div
                      style={{ backgroundPosition: "10px 10px" }}
                      className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]"
                    ></div>
                    <div className="relative rounded-xl overflow-auto">
                      <div className="shadow-sm overflow-hidden my-8">
                        <table className="border-collapse table-fixed w-full text-sm">
                          <thead>
                            <tr>
                              <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                Benefits
                              </th>

                              <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                Max Limit
                              </th>
                              <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                Unlimited
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white dark:bg-slate-800">
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                Technology Credits
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="technology_credits"
                                  label=""
                                  component={renderInputField}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="technology_credits_unlimited"
                                  label=""
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                Phone Number Credits
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="contactcredit"
                                  label=""
                                  component={renderInputField}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="contactcredit_unlimited"
                                  label=""
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                Business Email Credits
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="bus_email_credits"
                                  label=""
                                  component={renderInputField}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="bus_email_credits_unlimited"
                                  label=""
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                Personal Email Credits
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="personal_email_credits"
                                  label=""
                                  component={renderInputField}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="personal_email_credits_unlimited"
                                  label=""
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                API Keys
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="availableapicall"
                                  label=""
                                  component={renderInputField}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="apicallcount_unlimited"
                                  label=""
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                API rate limit per 1 minuite
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="api_rate_min"
                                  label=""
                                  component={renderInputField}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="api_rate_min_unlimited"
                                  label=""
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                API rate limit per 1 hour
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="api_rate_hr"
                                  label=""
                                  component={renderInputField}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="api_rate_hr_unlimited"
                                  label=""
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                API rate limit per day
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="api_rate_day"
                                  label=""
                                  component={renderInputField}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="api_rate_day_unlimited"
                                  label=""
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                Seat Available
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="availableseats"
                                  label=""
                                  component={renderInputField}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="seatcount_unlimited"
                                  label=""
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                CSV enrichment per day
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="csv_enr_day"
                                  label=""
                                  component={renderInputField}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="csv_enr_day_unlimited"
                                  label=""
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                Basic Filters Limit
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="basicfilters_limits"
                                  label=""
                                  component={renderSelect}
                                  opts={basicOptions}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"></td>
                            </tr>

                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                Bulk Show Types
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="bulkshow_types"
                                  label={null}
                                  component={renderSelect}
                                  opts={bulkOptions}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"></td>
                            </tr>

                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                CSV Enrichment Types
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="csvenrichment_types"
                                  label={null}
                                  component={renderSelect}
                                  opts={csnenrichmentOptions}
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"></td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                Extension Available?
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="extension_availability"
                                  label={null}
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"></td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                Crm Available?
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="crm_availablity"
                                  label={null}
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"></td>
                            </tr>
                            <tr>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                CSV Available?
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                <Field
                                  name="csvexport_availability"
                                  label={null}
                                  component={renderCheckbox}
                                  type="checkbox"
                                />
                              </td>
                              <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                  </div>
                  <div className="py-6 text-right">
                    {currentPlanDetails.hasOwnProperty("enterprise") &&
                    currentPlanDetails.enterprise != undefined ? (
                      <div className="text-right">
                        <div className="flex gap-2 justify-end text-base font-semibold">
                          <div>Invoice Generated</div>
                          <div> - </div>
                          <div>
                            Payment{" "}
                            {currentPlanDetails?.enterprise?.payment_status}
                          </div>
                        </div>
                        <div className="mt-4">
                          <button
                            type="button"
                            className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
                            onClick={() =>
                              sendInvoice(
                                currentPlanDetails?.enterprise?.planid
                              )
                            }
                          >
                            {processLoading ? (
                              <CustomSpinner />
                            ) : (
                              <span>Resend Invoice</span>
                            )}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
                      >
                        {processLoading ? (
                          <CustomSpinner />
                        ) : plan == "custom_yearly" ? (
                          <span>Assign Plan</span>
                        ) : (
                          <span>Generate Invoice</span>
                        )}
                      </button>
                    )}
                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const validate = (values, ownProps) => {
  let errors = {};
  if (!values.price) {
    errors.price = LocalStrings.validation_required;
  }
  if (!values.sku) {
    errors.sku = LocalStrings.validation_required;
  }
  if (!values.startdate) {
    errors.startdate = LocalStrings.validation_required;
  }
  if (!values.enddate) {
    errors.enddate = LocalStrings.validation_required;
  }
  return errors;
};

const mapStateToProps = (state) => {
  let initVals = {};

  if (state.currentPlanDetails && state.currentPlanDetails.enterprise) {
    let planid = state.currentPlanDetails.enterprise.planid;
    let validity = state.currentPlanDetails.enterprise.validity;
    let technology_credits =
      state.currentPlanDetails.enterprise.technology_credits;
    let contactcredit = state.currentPlanDetails.enterprise.contactcredit;
    let bus_email_credits =
      state.currentPlanDetails.enterprise.bus_email_credits;
    let personal_email_credits =
      state.currentPlanDetails.enterprise.personal_email_credits;
    let sku = state.currentPlanDetails.enterprise.sku;
    let api_rate_min = state.currentPlanDetails.enterprise.api_rate_min;
    let api_rate_hr = state.currentPlanDetails.enterprise.api_rate_hr;
    let api_rate_day = state.currentPlanDetails.enterprise.api_rate_day;
    let csv_enr_day = state.currentPlanDetails.enterprise.csv_enr_day;
    let availableseats = state.currentPlanDetails.enterprise.availableseats;
    let availableapicall = state.currentPlanDetails.enterprise.availableapicall;

    initVals.price = state.currentPlanDetails.enterprise.price;
    initVals.startdate = state.currentPlanDetails.enterprise.startdate;
    initVals.enddate = state.currentPlanDetails.enterprise.enddate;
    initVals.sku = state.currentPlanDetails.enterprise.sku;
    initVals.planid = planid;
    initVals.contactcredit = contactcredit;
    initVals.technology_credits = technology_credits;
    initVals.bus_email_credits = bus_email_credits;
    initVals.personal_email_credits = personal_email_credits;
    initVals.api_rate_min = api_rate_min;
    initVals.api_rate_hr = api_rate_hr;
    initVals.api_rate_day = api_rate_day;
    initVals.csv_enr_day = csv_enr_day;
    initVals.availableseats = availableseats;
    initVals.availableapicall = availableapicall;
    initVals.autorenew =
      state.currentPlanDetails.enterprise.autorenew == 1 ? true : false;
  } else if (
    state.enterPrisePlan &&
    state.enterPrisePlan.data &&
    state.enterPrisePlan.data.length > 0
  ) {
    let selectedPlan = _.filter(state.enterPrisePlan.data, (obj) => {
      return obj.sku == "custom_monthly";
    });
    if (selectedPlan.length > 0) {
      let planid = selectedPlan[0].planid;
      let validity = selectedPlan[0].validity;
      let technology_credits = selectedPlan[0].technology_credits;
      let contactcredit = selectedPlan[0].contactcredit;
      let bus_email_credits = selectedPlan[0].bus_email_credits;
      let personal_email_credits = selectedPlan[0].personal_email_credits;
      let sku = selectedPlan[0].sku;
      let api_rate_min = selectedPlan[0].api_rate_min;
      let api_rate_hr = selectedPlan[0].api_rate_hr;
      let api_rate_day = selectedPlan[0].api_rate_day;
      let csv_enr_day = selectedPlan[0].csv_enr_day;
      let availableseats = selectedPlan[0].availableseats;
      let availableapicall = selectedPlan[0].availableapicall;
      initVals.planid = planid;
      initVals.contactcredit = contactcredit;
      initVals.technology_credits = technology_credits;
      initVals.bus_email_credits = bus_email_credits;
      initVals.personal_email_credits = personal_email_credits;
      initVals.api_rate_min = api_rate_min;
      initVals.api_rate_hr = api_rate_hr;
      initVals.api_rate_day = api_rate_day;
      initVals.csv_enr_day = csv_enr_day;
      initVals.availableseats = availableseats;
      initVals.availableapicall = availableapicall;
    }
  }

  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    currentPlanDetails: state.currentPlanDetails,
    enterPrisePlan: state.enterPrisePlan,
    formValues: getFormValues("UpdatePlanForm")(state),
    initialValues: initVals,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchPlanDetails, updatePlan, fetchEnterpriseData, sendInvoice },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "UpdatePlanForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  })(UpdatePlan)
);
