import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";
import Pagination from "react-js-pagination";
import { fetchUsers } from "./actions";
import { CustomSpinner } from "../Common/base-component";
import { Toastify } from "../Common/Toastify";
import Loader from "../Common/Loader";

const UserList = (props) => {
  const { isLoggedIn, session, userList } = props;
  const Navigate = useNavigate();
  const Location = useLocation();
  const [pageNumber, setPagenumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [processLoading, setProcessLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  useEffect(() => {
    props.fetchUsers(session, null, 1, false, (res) => {});
  }, []);

  const paginationCallback = (pageNumber) => {
    //console.log("pageNumber", pageNumber);
    setPagenumber(pageNumber);
    props.fetchUsers(session, searchText, pageNumber, false, (res) => {});
  };

  const getAllData = () => {
    setProcessLoading(true);
    props.fetchUsers(session, null, 1, true, (res) => {
      setProcessLoading(false);
      if (res.success == 1) {
        downloadSample("csv", res.data);
      }
    });
  };

  const downloadSample = (type, data) => {
    let XLSX = require("xlsx");
    let sample = [];
    data.map((object, index) => {
      let obj = {};
      obj.name = object.name;
      obj.email = object.email;
      obj.planname = object.planname;
      obj.createdon = format(new Date(object.createdon), "do LLL,yyyy hh:mm a");
      sample.push(obj);
    });

    //console.log("sample updated", sample);
    /* Create a new empty workbook, then add the worksheet */
    let wb = XLSX.utils.book_new();
    // console.log("wb", wb);

    /* Create a worksheet */
    var ws = XLSX.utils.json_to_sheet(sample);
    var sheetName = "logs";

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /* Generate xlsx files */
    if (type === "csv") {
      XLSX.writeFile(wb, "logs.csv");
    } else {
      XLSX.writeFile(wb, "logs.xlsx");
    }
  };

  const _renderUsers = () => {
    return _.map(userList.data, (user, key) => {
      const isUpgradeEmptyArray =
        Array.isArray(user.upgrade) && user.upgrade.length === 0;

      // Check if upgrade is an object and not an array
      const isUpgradeObject =
        typeof user.upgrade === "object" &&
        !Array.isArray(user.upgrade) &&
        user.upgrade !== null;
      return (
        <tr>
          <td className="border-b border-slate-100 p-1.5 text-slate-500 pl-4">
            {user.name}
          </td>
          <td className="border-b border-slate-100 p-1.5 text-slate-500 truncate">
            {user.email}
          </td>
          <td className="  border-b border-slate-100 p-1.5 text-slate-500">
            <div>{user.planname}</div>
            <div className="text-xs text-gray-400">
              {(user.sku == "custom_trial" ||
                user.sku == "custom_monthly" ||
                user.sku == "custom_yearly") &&
              user.autorenew == 1
                ? "Auto renew on " + user.expirydate
                : user.downgrade_date
                ? "Downgrade starts on " + user.downgrade_date
                : user.autorenew == 1
                ? "Auto renew on " + user.nextstartdate
                : "Expired on " + user.expirydate}
            </div>
          </td>
          <td className="border-b border-slate-100 p-1.5 text-slate-500">
            {isUpgradeEmptyArray && <p>Not available.</p>}
            {isUpgradeObject && (
              <div className="text-sm">
                <p>
                  <strong>Name:</strong> {user.upgrade.name}
                </p>
                <p>
                  <strong>Payment Status:</strong> {user.upgrade.payment_status}
                </p>
                <p>
                  <strong>Payment Mode:</strong> {user.upgrade.paymode}
                </p>
                <p>
                  <strong>Start Date:</strong>{" "}
                  {format(new Date(user.upgrade.startdate), "do LLL,yyyy")}
                </p>
                <p>
                  <strong>End Date:</strong>{" "}
                  {format(new Date(user.upgrade.enddate), "do LLL,yyyy")}
                </p>
              </div>
            )}
          </td>
          <td className="border-b border-slate-100 p-1.5 text-slate-500">
            {format(new Date(user.createdon), "do LLL,yy hh:mm a")}
          </td>
          <td className="border-b border-slate-100 p-1.5 text-slate-500">
            <div className=" space-y-1">
              <div>
                <a
                  onClick={() => Navigate("/userdetails/" + user.clientid)}
                  className=" text-kipplocolor cursor-pointer"
                >
                  Manage
                </a>
              </div>
              <div>
                <a
                  onClick={() =>
                    Navigate("/reportedcontacts?id=" + user.appuserid)
                  }
                  className=" text-kipplocolor cursor-pointer"
                >
                  Reported Contacts
                </a>
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  const handleSearch = (e) => {
    let searchtext = e.target.value;
    setSearchText(searchtext);
  };

  const searchUser = () => {
    if (searchText) {
      setSearchLoading(true);
      props.fetchUsers(session, searchText, pageNumber, false, (res) => {
        setSearchLoading(false);
      });
    }
  };
  const clearSearch = () => {
    setClearLoading(true);
    setPagenumber(1);
    props.fetchUsers(session, null, 1, false, (res) => {
      setSearchText(null);
      setClearLoading(false);
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchUser();
    } else if (e.key === "Backspace") {
      if (searchText.length == 0) {
        clearSearch();
      }
    } else if (e.key === "Delete") {
      console.log("Delete key pressed");
      // Handle Delete functionality
    }
  };

  return (
    <>
      <article className="container px-4 mx-auto grid">
        <div className="grid grid-cols-4 mt-4">
          <div className="col-span-4">
            <div>
              <div className="flex flex-col justify-between mb-4">
                <div className="flex items-center justify-between">
                  <div className="flex flex-row gap-4">
                    <div>
                      <input
                        className="appearance-none px-3 py-2 border border-ternary rounded-md shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight sm:text-sm"
                        type="text"
                        id="searchtext"
                        name="searchtext"
                        value={searchText ? searchText : ""}
                        onChange={(e) => handleSearch(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        placeholder="Type Email"
                      />
                    </div>
                    <div className="flex">
                      <button
                        type="button"
                        className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
                        onClick={() => searchUser()}
                      >
                        {searchLoading ? (
                          <CustomSpinner />
                        ) : (
                          <span>Search</span>
                        )}
                      </button>
                      &nbsp;
                      {searchText ? (
                        <button
                          type="button"
                          className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
                          onClick={() => clearSearch()}
                        >
                          {clearLoading ? (
                            <CustomSpinner />
                          ) : (
                            <span>ClearAll</span>
                          )}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end items-center">
                    <button
                      type="button"
                      className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
                      onClick={() => getAllData()}
                    >
                      {processLoading ? (
                        <CustomSpinner />
                      ) : (
                        <span>Export All Users</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              {userList.data ? (
                userList.data.length > 0 ? (
                  <>
                    <div className="not-prose relative bg-slate-100 rounded-xl overflow-hidden">
                      <div className="relative rounded-xl overflow-auto my-2">
                        <div className="shadow-sm overflow-hidden">
                          <table className="border-collapse table-fixed w-full text-[16px]">
                            <thead>
                              <tr>
                                <th className="border-b font-medium p-1.5 text-left pl-4">
                                  Name
                                </th>

                                <th className="border-b font-medium p-1.5 text-left">
                                  Email
                                </th>
                                <th className="border-b font-medium p-1.5 text-left">
                                  Current Plan
                                </th>
                                <th className="border-b font-medium p-1.5 text-left">
                                  Upgrade Details
                                </th>
                                <th className="border-b font-medium p-1.5 text-left">
                                  Joined Since
                                </th>
                                <th className="border-b font-medium text-left" />
                              </tr>
                            </thead>
                            <tbody className="bg-white dark:bg-slate-800">
                              {_renderUsers()}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                    </div>
                    <div className="mt-4">
                      <Pagination
                        activePage={pageNumber}
                        itemsCountPerPage={10}
                        totalItemsCount={userList.totalcount}
                        pageRangeDisplayed={10}
                        onChange={(e) => paginationCallback(e)}
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center text-base ">
                    No User Found
                  </div>
                )
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    userList: state.userList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchUsers }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
