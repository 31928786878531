import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
// constants
// actions
import { setAddCodeModalDetails } from "../../actions";
// components
// files
import LogoutIcon from "../../assets/img/icons/logout.svg";
import LocalStrings from "../../languages/LocalStrings";
import { setProgram } from "../../actions";


const NavBar = (props) => {
  const { toggleSideMenu, partnerProgramReducer, session } = props;

  useEffect(() => {
   
  }, []);

  const _handleLogout = () => {
    props.setAddCodeModalDetails({
      show: true,
      calledFrom: "logout",
      data: {},
    });
  };

  
  return (
    <>
      <header className="z-20 py-2 bg-white shadow-md">
        <div className="grid grid-cols-2 container h-full px-6 mx-auto text-primary">
          <div className="col-span-1 h-full w-full flex items-center justify-between">
            <div>
             Hello
            </div>
          </div>
          <div className="col-span-1 h-full w-full flex items-center justify-end">
            <ul className="flex items-center flex-shrink-0 space-x-6">
              
              <li className="relative">
                <div
                  className="inline-flex items-center w-full px-2 py-1 text-sm text-primary-black font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 cursor-pointer"
                  onClick={_handleLogout}
                >
                  <span className="hidden lg:block mr-2 text-gray-600">
                    {LocalStrings.button_logout}
                  </span>
                  <img src={LogoutIcon} alt="logout" className="h-4 w-4 text-gray-600" />
                </div>
               
              </li>
            </ul>
          </div>
        </div>
        <div className=" flex items-center justify-end ">
         
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    partnerProgramReducer: state.partnerProgramReducer,
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setAddCodeModalDetails, setProgram },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
