import _ from "lodash";
import { INSTANCE_URL, TABLE_URL } from "../../Common/constant";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../Common/network-call";
import { reset } from "redux-form";

export var ENTERPRISE_PLAN = "ENTERPRISE_PLAN";

export function fetchEnterpriseData(session, callback) {
  console.log("session", session);
  let filter = encodeURI(`(sku is not null)`);

  var url = `${TABLE_URL}/subscriptionplan?filter=${filter}&order=planid%20ASC`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: ENTERPRISE_PLAN,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function updatePlan(session, values, planid, callback) {
  var url = `${TABLE_URL}/subscriptionplan/${planid}`;

  return (dispatch) => {
    patchRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}
